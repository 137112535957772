import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { Field } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'components/utils/phoneNumberValidation';

const PhoneNumber = ({
  name,
  placeholder,
  showErrorMessages = true,
  label,
}) => {
  const validate = phone => {
    if (phone) {
      const parsedPhoneNumber = parsePhoneNumberFromString(`+${phone}`);
      const isValid = !!isValidPhoneNumber(
        phone.substring(parsedPhoneNumber?.countryCallingCode?.length || 0),
        parsedPhoneNumber?.country
      );

      return isValid ? null : 'Invalid phone number';
    }
  };

  return (
    <Field name={name} validate={validate}>
      {({
        field: { name, value },
        field,
        meta,
        form: { setFieldValue, setFieldTouched },
      }) => (
        <div className="app-input-text">
          <PhoneInput
            country="us"
            name={name}
            placeholder={placeholder}
            value={value}
            isValid={() => !(meta.touched && meta.error)}
            countryCodeEditable={false}
            specialLabel={label ? label : false}
            {...field}
            onChange={phone => {
              setFieldValue(name, phone);
            }}
            onFocus={() => setFieldTouched(name, true)}
            onBlur={() => {}}
            enableSearch
          />
          {meta.touched && meta.error && showErrorMessages && (
            <p className="error-message">{meta.error}</p>
          )}
        </div>
      )}
    </Field>
  );
};

export default PhoneNumber;
