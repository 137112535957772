import React from 'react';
import FloatingLabel from 'floating-label-react';
import { Field } from 'formik';

export default ({
  name,
  placeholder,
  maxLength,
  floating = true,
  showErrorMessages = true,
  disabled = false,
}) => (
  <Field name={name}>
    {({ field, meta, form }) => (
      <div className="app-input-text">
        {floating ? (
          <FloatingLabel
            className={`${meta.touched && meta.error ? 'error' : ''}`}
            name={field.name}
            placeholder={placeholder}
            type="text"
            value={field.value || ''}
            maxLength={maxLength}
            disabled={disabled}
            {...field}
            onChange={evt => {
              const value = evt.currentTarget.value;
              if (value && maxLength && value.length > maxLength) {
                return;
              }
              form.setFieldValue(name, value);
            }}
          />
        ) : (
          <input
            className={`${meta.touched && meta.error ? 'error' : ''}`}
            name={field.name}
            placeholder={placeholder}
            type="text"
            value={field.value || ''}
            maxLength={maxLength}
            disabled={disabled}
            {...field}
          />
        )}
        {meta.touched && meta.error && showErrorMessages && (
          <p className="error-message">{meta.error}</p>
        )}
      </div>
    )}
  </Field>
);
